<template>
  <div class="cms-media-file-launcher">
    <file-uploader
      ref="launcherUploader"
      :path="path"
      @success="onUploadSuccess"
      @queuecomplete="onQueueComplete"
    ></file-uploader>
  </div>
</template>

<script>
import FileUploader from '@/modules/filesystem/components/File/FileUploader.vue';
import { UiIcon, UiItem } from '@/modules/ui/components';

export default {
  name: 'cms-media-link-editor',
  components: { FileUploader, UiIcon, UiItem },
  props: {
    path: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      uploadedFiles: []
    };
  },

  methods: {
    onUploadSuccess(files) {
      if (!Array.isArray(files)) {
        files = [files];
      }

      files.forEach(file => {
        this.$emit('input', {
          component: 'CmsMediaFile',
          props: {
            href: file.url,
            text: file.name,
            secondary: '',
            icon: 'mime:' + file.mimetype,
            name: file.name,
            size: file.size,
            mimetype: file.mimetype
          }
        });
      });
    },

    onQueueComplete() {
      this.$emit('cancel');
    }
  },

  mounted() {
    this.$refs.launcherUploader.open();
  }
};
</script>